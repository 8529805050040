import { FC } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { AlertFormValues } from "../../interfaces";
import { AlertEmail } from "../AlertEmail";
import { AlertRollStabilityParameters } from "../AlertParameters/AlertRollStabilityParameters";
import { AlertQueryBuilder } from "../AlertQueryBuilder";
import { AlertRecipients } from "../AlertRecipients";
import { AlertScheduleBlock } from "../AlertScheduleBlock";

interface AlertRollStabilityFormProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
  onSubmit?: (data: FieldValues) => void;
}

export const AlertRollStabilityForm: FC<AlertRollStabilityFormProps> = ({
  form,
  onSubmit,
}) => (
  <form
    data-testid="alert-roll-stability-form"
    autoComplete="off"
    onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
  >
    <Box className="px-4 md:px-8 lg:px-16">
      <AlertRollStabilityParameters form={form} />
      <AlertQueryBuilder form={form} />
      <Grid container spacing={3}>
        <AlertEmail form={form} />
        <AlertScheduleBlock form={form} />
        <AlertRecipients form={form} />
      </Grid>
    </Box>
  </form>
);

export default AlertRollStabilityForm;
