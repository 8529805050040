import { FC, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { ReactComponent as BrakePadSensorDark } from "../../../../../assets/svgs/brakePadSensorDark.svg";
import { ReactComponent as BrakePadSensorLight } from "../../../../../assets/svgs/brakePadSensorLight.svg";
import { ReactComponent as BrakeSystemSensorDark } from "../../../../../assets/svgs/brakeSystemSensorDark.svg";
import { ReactComponent as BrakeSystemSensorLight } from "../../../../../assets/svgs/brakeSystemSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  brakesPressureParkingDefaultValues,
  brakesPressureServiceDefaultValues,
  brakesTemperatureDefaultValues,
  brakesWearDefaultValues,
  extractReversedRules,
  extractRules,
  prepareReversedPayload,
  prepareRulesPayload,
  sliderMarks,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../shared/hooks/useGetTemperatureUnitPreference";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import {
  getConvertedTemperatureValues,
  getTemperatureUnitLabel,
  prepareConvertedCelsiusTemperaturesPayload,
} from "../../../../../utils/convertTemperature";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { BrakesDrawers, DrawerType } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";

export type BrakesDrawerProps = {
  sensorType: SensorProfileConfigType;
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const BrakesDrawer: FC<BrakesDrawerProps> = ({
  sensorType,
  sensorProfileData,
  type,
  isOpen,
  onClose,
  onProfileMutation,
  onRequestClose,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const temperatureUnitPreference = useGetTemperatureUnitPreference();
  const pressureUnitPreference = useGetPressureUnitPreference();

  const [minBrakeWear, maxBrakeWear] = [
    MinValuesBySensorType.brakeWear,
    MaxValuesBySensorType.brakeWear,
  ];
  const [minBrakeTemperatureConverted, maxBrakeTemperatureConverted] =
    getConvertedTemperatureValues(
      [
        MinValuesBySensorType.brakeTemperature,
        MaxValuesBySensorType.brakeTemperature,
      ],
      temperatureUnitPreference
    );
  const [minBrakePressureServiceConverted, maxBrakePressureServiceConverted] =
    getConvertedPressureValues(
      [
        MinValuesBySensorType.brakePressureService,
        MaxValuesBySensorType.brakePressureService,
      ],
      pressureUnitPreference
    );
  const [minBrakePressureParkingConverted, maxBrakePressureParkingConverted] =
    getConvertedPressureValues(
      [
        MinValuesBySensorType.brakePressureParking,
        MaxValuesBySensorType.brakePressureParking,
      ],
      pressureUnitPreference
    );

  const {
    wear: brakesWearThresholds,
    temperature: brakesTemperatureThresholds,
  } = sensorProfileData?.configuration?.brake ?? {};
  const {
    service: brakesPressureServiceThresholds,
    parking: brakesPressureParkingThresholds,
  } = sensorProfileData?.configuration?.brake?.pressure ?? {};

  const initialBrakesWearValues =
    extractReversedRules(brakesWearThresholds) || brakesWearDefaultValues;
  const initialBrakesTemperatureValues = getConvertedTemperatureValues(
    extractRules(brakesTemperatureThresholds) || brakesTemperatureDefaultValues,
    temperatureUnitPreference
  );
  const initialBrakesPressureServiceValues = getConvertedPressureValues(
    extractReversedRules(brakesPressureServiceThresholds) ||
      brakesPressureServiceDefaultValues,
    pressureUnitPreference
  );
  const initialBrakesPressureParkingValues = getConvertedPressureValues(
    extractReversedRules(brakesPressureParkingThresholds) ||
      brakesPressureParkingDefaultValues,
    pressureUnitPreference
  );

  const [brakesWearValues, setBrakesWearValues] = useState(
    initialBrakesWearValues
  );
  const [brakesTemperatureValues, setBrakesTemperatureValues] = useState(
    initialBrakesTemperatureValues
  );
  const [brakesPressureServiceValues, setBrakesPressureServiceValues] =
    useState(initialBrakesPressureServiceValues);
  const [brakesPressureParkingValues, setBrakesPressureParkingValues] =
    useState(initialBrakesPressureParkingValues);

  const [deleteBrakesPopupState, setDeleteBrakesPopupState] = useState(false);

  const isCreateMode = useMemo(() => {
    return type === BrakesDrawers.Create;
  }, [type]);

  const isEditMode = useMemo(() => {
    return type === BrakesDrawers.Edit;
  }, [type]);

  const availableOrgs = useAvailableOrgs();

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEditMode) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEditMode, sensorProfileData]);

  const { form: brakesMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const toggleDeleteBrakesProfilePopup = () => {
    setDeleteBrakesPopupState(!deleteBrakesPopupState);
  };

  const handleBrakesProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Brakes Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleBrakesProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleBrakesProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Brakes Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleBrakesProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleBrakesProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Brakes Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleBrakesProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleBrakesProfileCreateSuccess,
    createSensorProfileOnError: handleBrakesProfileCreateError,
    updateSensorProfileOnSuccess: handleBrakesProfileUpdateSuccess,
    updateSensorProfileOnError: handleBrakesProfileUpdateError,
    deleteSensorProfileOnSuccess: handleBrakesProfileDeleteSuccess,
    deleteSensorProfileOnError: handleBrakesProfileDeleteError,
  });

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleSubmitClick = async () => {
    const isMainFormValid = await brakesMainForm.trigger();

    if (!isMainFormValid) return;

    const allFormData: FieldValues = {
      ...brakesMainForm.getValues(),
    };

    const { thresholds: wearThresholds } = prepareReversedPayload(
      undefined,
      brakesWearValues,
      MinValuesBySensorType.brakeWear,
      MaxValuesBySensorType.brakeWear
    );

    const { thresholds: temperatureThresholds } = prepareRulesPayload(
      undefined,
      prepareConvertedCelsiusTemperaturesPayload(
        brakesTemperatureValues,
        temperatureUnitPreference
      ),
      MinValuesBySensorType.brakeTemperature,
      MaxValuesBySensorType.brakeTemperature
    );

    const { thresholds: pressureServiceThresholds } = prepareReversedPayload(
      undefined,
      prepareConvertedMbarPressuresPayload(
        brakesPressureServiceValues,
        pressureUnitPreference
      ),
      MinValuesBySensorType.brakePressureService,
      MaxValuesBySensorType.brakePressureService
    );

    const { thresholds: pressureParkingThresholds } = prepareReversedPayload(
      undefined,
      prepareConvertedMbarPressuresPayload(
        brakesPressureParkingValues,
        pressureUnitPreference
      ),
      MinValuesBySensorType.brakePressureParking,
      MaxValuesBySensorType.brakePressureParking
    );

    const configuration = {
      brake: {
        wear: {
          match: {
            thresholds: wearThresholds,
          },
        },
        temperature: {
          match: {
            thresholds: temperatureThresholds,
          },
        },
        pressure: {
          service: {
            match: {
              thresholds: pressureServiceThresholds,
            },
          },
          parking: {
            match: {
              thresholds: pressureParkingThresholds,
            },
          },
        },
      },
    };

    if (isCreateMode) {
      const sensorProfileData = {
        name: allFormData.name,
        configuration,
        default: allFormData.default ?? false,
        orgId: allFormData?.orgName ?? "",
      };

      createSensorProfile(sensorProfileData);
    } else if (isEditMode) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: sensorProfileData?.orgId, // send current orgId to BE by default
      };

      for (let fieldUpdated in brakesMainForm.formState.dirtyFields) {
        // Get correct id from dropdown object to send to backend
        if (fieldUpdated === "orgName")
          updateSensorProfilePayload.orgId = allFormData[fieldUpdated];
        else
          updateSensorProfilePayload[fieldUpdated] = allFormData[fieldUpdated];
      }

      updateSensorProfilePayload.configuration = configuration;

      updateSensorProfile(
        updateSensorProfilePayload as UpdateOrganizationSensorProfileInput
      );
    }
  };

  const isLoading =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;

  const isSliderDirty =
    brakesWearValues.toString() !== initialBrakesWearValues.toString() ||
    brakesTemperatureValues.toString() !==
      initialBrakesTemperatureValues.toString() ||
    brakesPressureServiceValues.toString() !==
      initialBrakesPressureServiceValues.toString() ||
    brakesPressureParkingValues.toString() !==
      initialBrakesPressureParkingValues.toString();
  const text = isEditMode ? "Edit Profile" : "Create Profile";

  return (
    <Drawer
      testId="brakes-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={() => onClose(false)} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="pb-10">
            <ProfilesDrawerMainForm
              form={brakesMainForm}
              disabled={isLoading}
              isEdit={isEditMode}
            />
            <div className="flex flex-col pb-10">
              <Grid container className="bg-background px-6">
                <Grid item xs={12} className="py-8">
                  <Box className="mb-16">
                    <Typography sx={{ fontWeight: "bold" }}>
                      Brake Wear
                      <span className="text-error">*</span>
                    </Typography>
                    {isLightTheme ? (
                      <BrakePadSensorDark style={svgIconSettings} />
                    ) : (
                      <BrakePadSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={brakesWearValues}
                    min={minBrakeWear}
                    max={maxBrakeWear}
                    onChange={setBrakesWearValues}
                    marks={sliderMarks(minBrakeWear, maxBrakeWear, "%")}
                    reversed
                  />
                </Grid>

                <Grid item xs={12} className="py-8">
                  <Box className="mb-16">
                    <Typography sx={{ fontWeight: "bold" }}>
                      Brake Temperature Settings
                      <span className="text-error">*</span>
                    </Typography>
                    {isLightTheme ? (
                      <BrakeSystemSensorDark style={svgIconSettings} />
                    ) : (
                      <BrakeSystemSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={brakesTemperatureValues}
                    min={minBrakeTemperatureConverted}
                    max={maxBrakeTemperatureConverted}
                    onChange={setBrakesTemperatureValues}
                    marks={sliderMarks(
                      minBrakeTemperatureConverted,
                      maxBrakeTemperatureConverted,
                      getTemperatureUnitLabel(temperatureUnitPreference, true)
                    )}
                  />
                </Grid>

                <Grid item xs={12} className="py-8">
                  <Box className="mb-16">
                    <Typography sx={{ fontWeight: "bold" }}>
                      Brake Service Pressure Settings{" "}
                      <span className="text-error">*</span>
                    </Typography>
                    {isLightTheme ? (
                      <BrakeSystemSensorDark style={svgIconSettings} />
                    ) : (
                      <BrakeSystemSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={brakesPressureServiceValues}
                    min={minBrakePressureServiceConverted}
                    max={maxBrakePressureServiceConverted}
                    onChange={setBrakesPressureServiceValues}
                    marks={sliderMarks(
                      minBrakePressureServiceConverted,
                      maxBrakePressureServiceConverted,
                      getPressureUnitLabel(pressureUnitPreference)
                    )}
                    reversed
                  />
                </Grid>

                <Grid item xs={12} className="py-8">
                  <Box className="mb-16">
                    <Typography sx={{ fontWeight: "bold" }}>
                      Brake Parking Pressure Settings{" "}
                      <span className="text-error">*</span>
                    </Typography>
                    {isLightTheme ? (
                      <BrakeSystemSensorDark style={svgIconSettings} />
                    ) : (
                      <BrakeSystemSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={brakesPressureParkingValues}
                    min={minBrakePressureParkingConverted}
                    max={maxBrakePressureParkingConverted}
                    onChange={setBrakesPressureParkingValues}
                    marks={sliderMarks(
                      minBrakePressureParkingConverted,
                      maxBrakePressureParkingConverted,
                      getPressureUnitLabel(pressureUnitPreference)
                    )}
                    reversed
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <DrawerActions
            deleteBtnTestId="btn-delete-brakes-profile"
            showDeleteBtn={isEditMode}
            disabled={isLoading}
            onCancel={() => onClose(false)}
            onDelete={toggleDeleteBrakesProfilePopup}
          />

          {deleteBrakesPopupState && isEditMode && (
            <DeleteProfileDialog
              isOpen={deleteBrakesPopupState}
              onClose={toggleDeleteBrakesProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteProfile}
              testId="brakes"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={
          isLoading ||
          (isEmpty(brakesMainForm.formState.dirtyFields) && !isSliderDirty)
        }
        testId="btn-brakes-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default BrakesDrawer;
