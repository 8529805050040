import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import { rollStabilityDrawerFormSchema } from "../components/RollStabilityForm/RollStabilityValidationSchema";

export enum RollStabilityValues {
  warning = "warning",
  alert = "alert",
  critical = "critical",
}

export interface RollStabilityFormValues {
  sudden: RollStabilityValues;
  continuous: RollStabilityValues;
}

export const rollStabilityFormInitialValues: RollStabilityFormValues = {
  sudden: RollStabilityValues.warning,
  continuous: RollStabilityValues.alert,
};

export const useRollStabilityForm = (
  incomingInitialValues: Partial<RollStabilityFormValues> = {}
) => {
  const form = useForm<RollStabilityFormValues>({
    resolver: yupResolver(rollStabilityDrawerFormSchema),
    defaultValues: omitBy(
      { ...rollStabilityFormInitialValues, ...incomingInitialValues },
      isNil
    ),
  });

  const getValues = useCallback(
    () =>
      rollStabilityDrawerFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues };
};
